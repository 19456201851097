// import logo from './logo.svg';
import './App.css';


 import Header from './Pages/Components/Header';
import Index from './Pages/Index';
import About from './Pages/About';
import Services from './Pages/Services'; 
 import Footer from './Pages/Components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



function App() {
  return (
    <div className="App">
      <Router>
      <Header/> 
        <Routes>
          <Route path='/' element={<Index/>} />
          <Route path='/about' element={<About/>} />

          <Route path='/services' element={<Services/>} />
        </Routes>
        <Footer/> 
      </Router>

      {/* <Header/>
      <Index/>
      <Footer/> */}
      
    </div>
  );
}

export default App;
