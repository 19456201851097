import React from "react";

import { Link } from "react-router-dom";

function Header()
{
    return(
        <React.Fragment>
          
      <div className="header_top">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="call_text"><a href="#"><img src="assets/images/map-icon.png"/><span className="call_text_left">Locations</span></a></div>
            </div>
            <div className="col-sm-4">
              <div className="call_text"><a href="#"><img src="assets/images/call-icon.png"/><span className="call_text_left">+01 1234567890</span></a></div>
            </div>
            <div className="col-sm-4">
              <div className="call_text"><a href="#"><img src="assets/images/mail-icon.png"/><span className="call_text_left">Demo@gmail.com</span></a></div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="header_section">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="logo"><a href="index.html"><img src="assets/images/logo.png"/></a></div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">HOME</Link>
                {/* <a className="nav-link" href="index.html"></a> */}
              </li>
              <li className="nav-item">
              <Link className="nav-link" to="/about">ABOUT US</Link>
                {/* <a className="nav-link" href="about.html"></a> */}
              </li>
              <li className="nav-item">
              <Link className="nav-link" to="/services">SERVICES</Link>
                {/* <a className="nav-link" href="services.html">SERVICES</a> */}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="customer.html">CUSTOMER</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="news.html">NEWS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="contact.html">CONTACT US</a>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="#">LOGIN</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">SIGNUP</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"><img src="assets/images/search-icon.png"/></a>
              </li>
            </ul>
          </div>
        </nav>
        </div>
      </div>
      
        </React.Fragment>
    );
}


export default Header;