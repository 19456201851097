import React from "react";

function Footer()
{
    return(
        <React.Fragment>
             
      <div className="contact_section layout_padding">
        <div className="footer_section layout_padding">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <div className="footer_logo"><img src="assets/images/footer-logo.png"/></div>
                <p className="dolor_amet_text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it </p>
              </div>
              <div className="col-sm-4">
                <h1 className="footer_about">About Us</h1>
                <p className="dolor_amet_text_1">distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has</p>
              </div>
              <div className="col-sm-4">
                <h1 className="footer_about">Newsletter</h1>
                <input type="text" className="mail_text" placeholder="Enter your Email" name="text"/>
                <button className="subscribe_bt">Send</button>
              </div>
            </div>
          </div>
        </div>
      
      </div>
     
      <div className="copyright_section">
        <div className="container">
          <p className="copyright">2023 All Rights Reserved. <a href="https://html.design">Free html  Templates</a> Distributed By <a href="https://themewagon.com">ThemeWagon</a></p>
        </div>
      </div>
     
      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/popper.min.js"></script>
      <script src="assets/js/bootstrap.bundle.min.js"></script>
      <script src="assets/js/jquery-3.0.0.min.js"></script>
      <script src="assets/js/plugin.js"></script>
      
      <script src="assets/js/jquery.mCustomScrollbar.concat.min.js"></script>
      <script src="assets/js/custom.js"></script>
     
      <script src="assets/js/owl.carousel.js"></script>
      <script src="https:cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.5/jquery.fancybox.min.js"></script>
        </React.Fragment>
    );
}


export default Footer;